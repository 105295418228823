import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxCustom, DarkButton, LightButton, RadioCustom, RedTextButton, SearchDropDown } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { EquipSetting } from "system/types";
import ChangePopUp from "./ChangePopUp";

interface ChangeSheetModalProps {
  data: EquipSetting;
  nation: string;
  onLoad: () => void;
}

function ChangeSheetModal({ data, nation, onLoad }: ChangeSheetModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<EquipSetting>(data);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    Loading({ type: "LOADING" });
    ProductSearchApi.PutEquipSetting(updatedData.equipSerial, nation, updatedData)
      .then(() => {
        onLoad();
        setOpen(false);
        setPopup(true);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onChangeData = (e: any) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const onCheckedData = (e: any) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      resultSheetDetail: {
        ...prevData.resultSheetDetail,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  return (
    <div>
      <RedTextButton onClick={handleClickOpen}>{t("edit")}</RedTextButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" style={{ marginTop: "65px" }}>
        <DialogContent style={{ padding: "56px 65px" }}>
          <Grid container spacing={2} display="flex" alignItems="center">
            {/* 결과지 설정 */}
            <Grid item xs={12} style={{ marginTop: "40px" }}>
              <TitleDiv title={t("results-sheet-settings")}></TitleDiv>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} display="flex">
              <Grid item xs={6} style={{ paddingTop: "9px" }}>
                <Typography variant="body2" fontWeight="bold" width="150px">
                  {t("results-sheet-type")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CheckboxCustom
                        checked={updatedData.resultSheetDetail.isUseInBodyResultSheet}
                        onChange={onCheckedData}
                        name="isUseInBodyResultSheet"
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>{t("inBody-result-sheet")}</span>}
                  />
                  {updatedData.equip !== "INBODY270" && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseInBodyChildResultSheet}
                          onChange={onCheckedData}
                          name="isUseInBodyChildResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("inBody-result-sheet-for-children")}</span>}
                    />
                  )}
                  {["INBODY970", "INBODY770"].includes(updatedData.equip) && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseWaterResultSheet}
                          onChange={onCheckedData}
                          name="isUseWaterResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("body-water-result-sheet")}</span>}
                    />
                  )}
                  {updatedData.equip !== "INBODY270" && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseBodyTypeResultSheet}
                          onChange={onCheckedData}
                          name="isUseBodyTypeResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("body-type-result-sheet")}</span>}
                    />
                  )}
                  {updatedData.equip === "INBODY970" && (
                    <>
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseResearchResultSheet}
                            onChange={onCheckedData}
                            name="isUseResearchResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("research-result -sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseCompareResultSheet}
                            onChange={onCheckedData}
                            name="isUseCompareResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("comparison-result-sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseOptionResultSheet}
                            onChange={onCheckedData}
                            name="isUseOptionResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("evaluation-result-sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseVisceralFatResultSheet}
                            onChange={onCheckedData}
                            name="isUseVisceralFatResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("visceral-fat-result-sheet")}</span>}
                      />
                    </>
                  )}
                  {["INBODY380", "INBODY370S", "INBODY270"].includes(updatedData.equip) && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseThermalResultSheet}
                          onChange={onCheckedData}
                          name="isUseThermalResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("thermal-result-sheet")}</span>}
                    />
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("print-results-A4")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.isResultSheetPaperA4}
                onChange={onChangeData}
                name="isResultSheetPaperA4"
                fields={{ text: "displayText", value: "value" }}
                dataSource={[
                  { displayText: t("use-on"), value: true },
                  { displayText: t("use-off"), value: false },
                ]}
              ></SearchDropDown>
            </Grid>
            <Grid item xs={12} display="flex">
              <Grid item xs={6} style={{ paddingTop: "9px" }}>
                <Typography variant="body2" fontWeight="bold" width="150px">
                  {t("automatic-printing")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <RadioGroup name="autoPrint" value={updatedData.autoPrint} onChange={onChangeData}>
                    <FormControlLabel
                      style={{ marginRight: "80px" }}
                      value="Page1AutoPrint"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("on-1Copy")}</span>}
                    />
                    <FormControlLabel
                      value="Page2AutoPrint"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("on-2Copies")}</span>}
                    />
                    <FormControlLabel
                      value="NotUse"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("unused")}</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" style={{ marginTop: "70px" }}>
            <LightButton onClick={handleClose}>{t("cancel")}</LightButton>
            <DarkButton onClick={handleSave} style={{ marginBottom: "0px", marginLeft: "8px" }}>
              {t("save")}
            </DarkButton>
          </Grid>
        </DialogContent>
      </Dialog>
      {popup && <ChangePopUp popup={popup}></ChangePopUp>}
    </div>
  );
}

export default ChangeSheetModal;
