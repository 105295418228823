import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import { useTranslation } from "react-i18next";
import { TableContentCell, TitleCell } from "style/theme";
import { FirmwareLang } from "system/Constants";
import { FirmwareReq } from "system/types";

interface CurrentVersionProps {
  initData: FirmwareReq;
}

function CurrentVersion({ initData }: CurrentVersionProps) {
  const { t } = useTranslation();
  const resourceLabels = initData.resources.map((key) => {
    const lang = FirmwareLang.find((lang) => lang.key === key);
    return lang ? lang.label : key; // key가 없으면 그대로 표시
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubTitleDiv title={t("current-version")}></SubTitleDiv>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px", height: "100%" }}>
        <TableContainer
          style={{
            border: "1px solid #d9d9d9",
            overflow: "hidden",
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("digital") + ": "}</TitleCell>
                <TableContentCell>{initData?.digital}</TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}></TitleCell>
                <TableContentCell style={{ paddingTop: "0px" }}>{resourceLabels.join(", ")}</TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("analog") + ": "}</TitleCell>
                <TableContentCell>{initData?.analog}</TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("loadcell") + ": "}</TitleCell>
                <TableContentCell>{initData?.loadcell}</TableContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default CurrentVersion;
