export const NationType = [
  { key: "kr", label: "Korea" },
  { key: "en", label: "USA" },
];
export const AuthorityType = [
  { key: "Admin", label: "Admin" },
  { key: "HeadA", label: "본사 사용자 A" },
  { key: "HeadB", label: "본사 사용자 B" },
  { key: "BranchUser", label: "지사 사용자" },
  { key: "Engineer", label: "Engineer" },
  { key: "User", label: "User" },
];
export const EquipCountryCodeISOType = [
  "None",
  "Argentina",
  "Australia",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Taiwan",
  "Colombia",
  "CostaRica",
  "Cuba",
  "Czech",
  "Denmark",
  "Ecuador",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "HongKong",
  "Hungary",
  "India",
  "Indonesia",
  "Iran",
  "Israel",
  "Italy",
  "Japan",
  "RepublicOfKorea",
  "Lebanon",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "Norway",
  "Peru",
  "Poland",
  "Portugal",
  "PuertoRico",
  "Romania",
  "Russia_Kazakhstan",
  "SaudiArabia",
  "Serbia",
  "Singapore",
  "Vietnam",
  "RepublicOfSouthAfrica",
  "Spain",
  "Sweden",
  "Switzerland",
  "Thailand",
  "UAE",
  "Turkey",
  "Ukraine",
  "Egypt",
  "UnitedKingdom",
  "USA",
  "Venezuela",
];
export const EquipLanguageType = [
  "None",
  "English",
  "Arab",
  "Bulgaria",
  "Chinese",
  "Czech",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Italy",
  "Japanese",
  "Korea",
  "Netherlands",
  "Polish",
  "Portuguese",
  "PortugueseBrazil",
  "Romania",
  "Russian",
  "Slovak",
  "Spanish",
  "SpanishMexico",
  "Taiwan",
  "Thiland",
  "Turkish",
];

export const EquipConnectSerialPortType = [
  { key: "NotUse", label: "Off" },
  { key: "LB120", label: "LB120" },
  { key: "BloodPressure", label: "Blood Pressure" },
  { key: "Height", label: "Height" },
  { key: "ThermalPrinter", label: "Thermal Printer" },
  { key: "SD400", label: "SD400" },
  { key: "NAYAX", label: "NAYAX" },
];

export const EquipUnitTypestring = [
  { key: "KG_CM", label: "kg/cm" },
  { key: "KG_FtIn", label: "kg/ft in" },
  { key: "Pound_CM", label: "lb/cm" },
  { key: "Pound_FtIn", label: "lb/ft in" },
];

// export const FirmwareState = {
//   UP_TO_DATE: 0, // 최신 버전 없을 때
//   CONNECTING: 1, // 모달(연결중)
//   CONNECT_AVAILABLE: 2, // 최신 버전 있을 떄
//   CONNECT_SUCCESS: 3, // 모달(연결 성공) = 다운로드 가능
//   CONNECT_ERROR: 4, // 모달(연결 오류)
//   DOWNLOADING: 5, // 모달(다운로드중)
//   DOWNLOAD_CONFIRM: 6, // 모달(다운로드 수락)
//   DOWNLOAD_SUCCESS: 7, // 다운로드 성공
//   DOWNLOAD_ERROR: 8, // 다운로드 오류
// } as const;

export const FirmwareLang = [
  { key: "KR", label: "Korean" },
  { key: "EN", label: "English" },
  { key: "USA", label: "USA English" },
  { key: "JP", label: "Japanese" },
  { key: "CH", label: "Chinese" },
  { key: "BG", label: "Bulgaria" },
  { key: "CZ", label: "Czech" },
  { key: "FI", label: "Finnish" },
  { key: "FR", label: "French" },
  { key: "GE", label: "German" },
  { key: "GR", label: "Greece Greek" },
  { key: "IT", label: "Italian" },
  { key: "NL", label: "Netherlands Dutch" },
  { key: "PL", label: "Poland Polish" },
  { key: "PT", label: "Portugal" },
  { key: "PT_BR", label: "Portugal(Brazil)" },
  { key: "RO", label: "Romania" },
  { key: "SK", label: "Slovakia" },
  { key: "SP", label: "Spanish" },
  { key: "SP_MX", label: "Spanish(Mexico)" },
  { key: "SV", label: "Slovenia Swedish" },
  { key: "TH", label: "Thailand" },
  { key: "TR", label: "Turkish" },
  { key: "TW", label: "Taiwan Chinese(Traditional)" },
  { key: "USA_FR", label: "USA French" },
  { key: "RU", label: "Russian" },
  { key: "AR", label: "Arab" },
  { key: "SW", label: "Swiss" },
  { key: "VI", label: "Vietnamese" },
  { key: "TE", label: "Telugu" },
  { key: "HI", label: "Hindi" },
  { key: "TA", label: "Tamil" },
  { key: "DN", label: "덴마크" },
  { key: "HG", label: "헝가리" },
  { key: "NW", label: "노르웨이" },
  { key: "SI", label: "세르비아" },
];
