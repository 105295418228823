import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Dialog, DialogContent, Grid, LinearProgress, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DarkButton, LightButton } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { FirmwareDownload, FirmwareModal, FirmwareResources, FirmwareType } from "system/types";

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  modalName: FirmwareModal;
  setModalName: React.Dispatch<React.SetStateAction<FirmwareModal>>;
  status: FirmwareType;
  setStatus: React.Dispatch<React.SetStateAction<FirmwareType>>;
  serial: string;
  resources: FirmwareResources[];
}

function CustomModal({
  open,
  onClose,
  modalName,
  setModalName,
  status,
  setStatus,
  serial,
  resources,
}: CustomModalProps) {
  const { t } = useTranslation();
  const getIcon = () => {
    switch (modalName) {
      case "connect-error":
        return <SvgIcon component={ErrorOutlineIcon} style={{ fontSize: "50px" }} sx={{ color: "#ec033f" }} />;
      case "connect-success":
        return <SvgIcon component={CheckCircleOutlineIcon} style={{ fontSize: "50px" }} sx={{ color: "#4caf50" }} />;
      default:
        return <div style={{ height: "50px" }}></div>;
    }
  };
  const renderTitle = () => {
    switch (modalName) {
      case "connect":
        return <>{t("connecting")}</>;
      case "connect-success":
        return <>{t("successfully-connected")}</>;
      case "connect-error":
        return <>{t("unable-to-connect")}</>;
      case "down":
        return <>{t("downloading")}</>;
      case "down-confirm":
        return <>{t("confirm-download")}</>;
      default:
        return <>Unknown state</>;
    }
  };
  const renderContent = () => {
    switch (modalName) {
      case "connect":
        return <> {t("connecting-message")}</>;
      case "connect-success":
        return <>{t("success-connect-message")}</>;
      case "connect-error":
        return <>{t("connect-error")}</>;
      case "down-confirm":
        return <>{t("confirm-message")}</>;
      case "down":
        return <>{t("downloading-message")}</>;
      default:
        return <>Unknown state</>;
    }
  };

  const onClickDownload = () => {
    setModalName("down");
    const data: FirmwareDownload = {
      equipSerial: serial,
      downloadIds: resources.filter((resource) => resource.checked && resource.id !== 0).map((resource) => resource.id),
    };
    // api 통신
    ProductSearchApi.DownloadFirmware(data)
      .then((res) => {
        onClose();
        setStatus("down-success");
      })
      .catch((err) => {
        onClose();
        setStatus("down-error");
      });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "740px", // 너비 설정
          height: "400px", // 길이 설정
          maxWidth: "none", // maxWidth 기본값 무시
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={2} display="flex" alignItems="center">
          <Grid item xs={12} textAlign="center" style={{ marginTop: "15px" }}>
            {getIcon()}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" style={{ fontSize: "30px" }} paragraph textAlign="center">
              {renderTitle()}
            </Typography>
          </Grid>
          {(modalName === "connect" || modalName === "down") && (
            <Grid item xs={12}>
              <LinearProgress color="inherit" />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Typography
              variant="body1"
              paragraph
              textAlign="center"
              style={{ fontSize: "18px", whiteSpace: "pre-line" }}
            >
              {renderContent()}
            </Typography>
          </Grid>
        </Grid>
        {modalName === "down-confirm" ? (
          <Grid item xs={12} textAlign="center" style={{ marginTop: "50px" }}>
            <LightButton style={{ width: "260px" }} onClick={onClose}>
              {t("cancel")}
            </LightButton>
            <DarkButton style={{ width: "260px", marginBottom: "0px", marginLeft: "4px" }} onClick={onClickDownload}>
              {t("download")}
            </DarkButton>
          </Grid>
        ) : (
          <Grid item xs={12} textAlign="center" style={{ marginTop: "50px" }}>
            <DarkButton style={{ width: "260px" }} onClick={onClose}>
              {t("close")}
            </DarkButton>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CustomModal;
