import { Box, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentCell, TableContentCell, TitleCell, TableTitleCell } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { MaterialHistory, ProductInfo } from "system/types";

interface DetailInfoProps {
  serial: string;
}

function DetailInfo({ serial }: DetailInfoProps) {
  const { t } = useTranslation();
  const [info, setInfo] = useState<ProductInfo>();
  const [materialHistory, setMaterialHistory] = useState<MaterialHistory[]>([]);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        Loading({ type: "LOADING" });
        const infoRes = await ProductSearchApi.GetInfomationOfProduct(serial);
        setInfo(infoRes[0]);

        const materialHistoryRes = await ProductSearchApi.GetMaterialHistoryOfProduct(serial);
        setMaterialHistory(materialHistoryRes);
      } catch (err: any) {
        let msg = ErrorHandler(err);
        console.log(msg);
      } finally {
        Loading({ type: "COMPLETE" });
      }
    };

    fetchData();
  }, [Loading, serial]);

  return (
    <Grid container spacing={2} display="flex" justifyContent={"space-between"}>
      <Grid item xs={12}>
        <SubTitleDiv title="Client Information"></SubTitleDiv>
      </Grid>
      <Grid item xs={8}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                  <TitleCell>{t("business-name")}</TitleCell>
                  <ContentCell>{info ? info.bizName : "-"}</ContentCell>
                </TableRow>
                <TableRow>
                  <TitleCell>{t("current-location")}</TitleCell>
                  <ContentCell>{info ? info.currentCustomer : "-"}</ContentCell>
                </TableRow>
                {/* <TableRow>
                  <TitleCell>{t("mobile-number")}</TitleCell>
                  <ContentCell>{""}</ContentCell>
                </TableRow>
                <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <TitleCell>{"Email"}</TitleCell>
                  <ContentCell>{""}</ContentCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "70px" }}>
          <SubTitleDiv title="Device History"></SubTitleDiv>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "16px" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                  <TitleCell>{t("model")}</TitleCell>
                  <ContentCell>{info ? info.itemName : "-"}</ContentCell>
                </TableRow>
                <TableRow>
                  <TitleCell>{t("manufacturer")}</TitleCell>
                  <ContentCell>{info ? info.productionPerson : "-"}</ContentCell>
                </TableRow>
                <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <TitleCell>{t("manufacture-date")}</TitleCell>
                  <ContentCell>{info ? info.prodDate : "-"}</ContentCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            width: 350,
            height: 500,
            border: "1px solid #d9d9d9",
            marginBottom: "28px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            alt="product-img"
            src={`/images/product/${info?.itemName.split(/_|\s/)[1] + ".jpg"}`}
            style={{
              width: "100%",
              height: "100%",
            }}
            onError={(e) => {
              e.currentTarget.src = "/images/NO_Image.png";
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "60px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableTitleCell>{t("major-management-part")}</TableTitleCell>
                <TableTitleCell>{t("serial-number")}</TableTitleCell>
                <TableTitleCell>{t("program-version1")}</TableTitleCell>
                <TableTitleCell>{t("program-version2")}</TableTitleCell>
              </TableRow>
              {materialHistory.map((e: MaterialHistory) => {
                return (
                  <TableRow key={e.materialItemId + e.materialSerialId}>
                    <TableContentCell>{e.materialItemName}</TableContentCell>
                    <TableContentCell>{e.materialSerialId}</TableContentCell>
                    <TableContentCell>{e.programVersion1}</TableContentCell>
                    <TableContentCell>{e.programVersion2}</TableContentCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default DetailInfo;
