import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useCallback, useEffect, useState } from "react";
import { DarkButton, FormNumericBox, FormTextBox } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { ChangeCategory, INIT_CHANGECATEGORY, TreeItemCategory } from "system/types";

function AdminCategory() {
  const [categories, setCategories] = useState<TreeItemCategory[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<TreeItemCategory[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeData, setChangeData] = useState<ChangeCategory>(INIT_CHANGECATEGORY);

  const onLoad = useCallback(() => {
    CRMApi.GetItemCategory(true)
      .then((res) => {
        setCategories(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleCategoryClick = (category: TreeItemCategory) => {
    setSelectedSubCategories(category.children || []);
  };

  const handleSubCategoryClick = (subCategory: TreeItemCategory) => {
    setChangeData({
      ...changeData,
      origin: subCategory.id,
      new: subCategory.name,
      isShow: subCategory.isShow,
      order: subCategory.order,
    });
    setModalOpen(true);
  };

  const onChangeData = (e: any) => {
    setChangeData({ ...changeData, [e.target.name]: e.target.value });
  };

  const onChangeCheck = (e: any) => {
    setChangeData({ ...changeData, isShow: e.target.checked });
  };

  const handleSave = () => {
    CRMApi.ChangeCategory(changeData)
      .then(() => {
        const updatedSubCategories = selectedSubCategories.map((subCategory) =>
          subCategory.id === changeData.origin
            ? { ...subCategory, name: changeData.new, isShow: changeData.isShow, order: changeData.order }
            : subCategory
        );

        // Sort the subcategories based on the updated 'order' value
        const sortedSubCategories = updatedSubCategories.sort((a, b) => b.order - a.order);

        // Update state with the sorted list
        setSelectedSubCategories(sortedSubCategories);
        setModalOpen(false);
        setChangeData(INIT_CHANGECATEGORY);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  };

  const closeModal = () => {
    setChangeData(INIT_CHANGECATEGORY);
    setModalOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubTitleDiv title="제품 카테고리"></SubTitleDiv>
      </Grid>
      <Grid item xs={3}>
        <TableContainer component={Paper} style={{ height: "500px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>제품군</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category.id} hover onClick={() => handleCategoryClick(category)}>
                  <TableCell>{category.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3}>
        <TableContainer component={Paper} style={{ height: "500px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>모델명</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSubCategories.map((subCategory: TreeItemCategory, index: number) => (
                <TableRow key={subCategory.id + index} hover onClick={() => handleSubCategoryClick(subCategory)}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {subCategory.name ? `(${subCategory.id}) ${subCategory.name}` : subCategory.id}
                      {subCategory.isShow ? (
                        <VisibilityIcon style={{ marginLeft: 8 }} />
                      ) : (
                        <VisibilityOffIcon style={{ marginLeft: 8 }} />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog open={modalOpen} onClose={closeModal} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "50px" }}>
          <Grid container spacing={2} textAlign="center">
            <Grid item xs={12}>
              <TitleDiv title={`${changeData?.origin}`}></TitleDiv>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                CS 사이트 내에서 사용할 이름과 보여줄지 여부를 선택할 수 있습니다. 변경 사항은 ERP와 연동되지 않고 CS
                사이트에서만 반영됩니다.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                placeholder="변경할 이름"
                floatLabelType="Auto"
                name="new"
                cssClass="e-outline"
                value={changeData.new}
                onChange={onChangeData}
                fullWidth
              ></FormTextBox>
            </Grid>
            <Grid item xs={6}>
              <FormNumericBox
                placeholder="순서"
                floatLabelType="Auto"
                name="order"
                cssClass="e-outline"
                min={0}
                format="n0"
                value={changeData.order}
                onChange={onChangeData}
                fullWidth
              ></FormNumericBox>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox name="isShow" checked={changeData.isShow} onChange={onChangeCheck} />}
                label="show"
              />
            </Grid>
            <Grid item xs={12}>
              <DarkButton onClick={handleSave}>저장</DarkButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default AdminCategory;
