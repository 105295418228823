import { Grid, Tab, Tabs } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PRIMARY } from "style/theme";
import { ProductSearchRoutes } from "system/types/routeList";
import FirmwareMain from "../Firmware/FirmwareMain";
import QCDataMain from "../QCData/QCDataMain";
import SettingMain from "../Setting/SettingMain";
import DetailInfo from "./DetailInfo";

function ProductMain() {
  const { t } = useTranslation();
  const { id, nation } = useParams<{ id: string; nation: string }>();
  const [value, setValue] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    localStorage.setItem("tabs", newValue.toString());
    history.push(`${ProductSearchRoutes.root}/detail/${nation}/${id}?tab=${newValue}`);
  };

  useEffect(() => {
    const tabValue = new URLSearchParams(location.search).get("tab");
    if (tabValue) {
      setValue(parseInt(tabValue));
    }
  }, [location.search]);

  return (
    <Grid container style={{ width: "1400px" }}>
      <Grid item xs={12}>
        <TitleDiv title={t("product-information")}></TitleDiv>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ borderBottom: "1px solid #d9d9d9" }}
      >
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{
              style: { background: PRIMARY },
            }}
          >
            <Tab
              key={1}
              value={1}
              label={t("details")}
              sx={{ fontSize: "18px", fontWeight: value === 1 ? "bold" : "normal" }}
            />
            <Tab
              key={2}
              value={2}
              label="QC Data"
              sx={{ fontSize: "18px", fontWeight: value === 2 ? "bold" : "normal" }}
            />
            <Tab
              key={3}
              value={3}
              label={t("device-setup")}
              sx={{ fontSize: "18px", fontWeight: value === 3 ? "bold" : "normal" }}
            />
            <Tab
              key={4}
              value={4}
              label={t("firmware-update")}
              sx={{ fontSize: "18px", fontWeight: value === 3 ? "bold" : "normal" }}
            />
          </Tabs>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "5px" }}>{t("serial-number") + ": "}</span>
          <SubTitleDiv title={id} />
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "40px" }}>
        {value === 1 && <DetailInfo serial={id}></DetailInfo>}
        {value === 2 && <QCDataMain nation={nation} serial={id}></QCDataMain>}
        {value === 3 && <SettingMain nation={nation} serial={id}></SettingMain>}
        {value === 4 && <FirmwareMain nation={nation} serial={id}></FirmwareMain>}
      </Grid>
    </Grid>
  );
}

export default ProductMain;
