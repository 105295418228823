import { FormControlLabel, Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlackFormSelectCheckBox, TableContentCell, TitleCell } from "style/theme";
import { FirmwareReq, FirmwareResources, FirmwareType } from "system/types";

interface LatestVersionProps {
  latestData: FirmwareReq;
  status: FirmwareType;
  resources: FirmwareResources[];
  setResources: React.Dispatch<React.SetStateAction<FirmwareResources[]>>;
}

function LatestVersion({ latestData, status, resources, setResources }: LatestVersionProps) {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setResources(resources.map((item: any) => (item.key === key ? { ...item, checked: event.target.checked } : item)));
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setResources(
      resources.map((item: any) => ({
        ...item,
        checked: !item.disabled && isChecked, // Only update if not disabled
      }))
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubTitleDiv title={t("latest-version")}></SubTitleDiv>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "6px",
            overflow: "hidden",
            opacity: status === "update-available" ? 0.5 : 1, // 테이블 비활성화 시 투명도 적용
            backgroundColor: status === "update-available" ? "#ececec" : "inherit",
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("digital") + ": "}</TitleCell>
                <TableContentCell>{latestData?.digital}</TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}></TitleCell>
                <TableContentCell>
                  {/* 전체 선택 체크박스 */}
                  <FormControlLabel
                    style={{ width: "100%" }}
                    control={
                      <BlackFormSelectCheckBox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        disabled={status !== "down-available"}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: status !== "down-available" ? "#9e9e9e" : "inherit",
                        }}
                      >
                        {"Select All"}
                      </Typography>
                    }
                  />
                  {/* 개별 체크박스 */}
                  {resources.map((item) => (
                    <FormControlLabel
                      key={item.key}
                      style={{ width: "200px" }}
                      control={
                        <BlackFormSelectCheckBox
                          checked={item.checked}
                          onChange={(e) => handleCheckboxChange(e, item.key)}
                          disabled={item.disabled || status !== "down-available"}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: item.disabled ? "#9e9e9e" : "inherit",
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  ))}
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("analog") + ": "}</TitleCell>
                <TableContentCell>{latestData?.analog}</TableContentCell>
              </TableRow>
              <TableRow>
                <TitleCell style={{ width: "100px" }}>{t("loadcell") + ": "}</TitleCell>
                <TableContentCell>{latestData?.loadcell}</TableContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default LatestVersion;
