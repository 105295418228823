import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentCell, TitleCell } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { EquipConnectSerialPortType, EquipUnitTypestring } from "system/Constants";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import { EquipSetting, INIT_EQUIPSETTING, ResultSheet } from "system/types";
import ChangeModal from "./ChangeModal";
import ChangeSheetModal from "./ChangeSheetModal";

interface SettingMainProps {
  nation: string;
  serial: string;
}

function SettingMain({ serial, nation }: SettingMainProps) {
  const user = useUserState();
  const { t } = useTranslation();
  const [data, SetData] = useState<EquipSetting>(INIT_EQUIPSETTING);
  const Loading = useLoadingDispatch();
  const DisplayResultSheet = {
    isUseInBodyResultSheet: t("inBody-result-sheet"),
    isUseInBodyChildResultSheet: t("inBody-result-sheet-for-children"),
    isUseBodyTypeResultSheet: t("body-type-result-sheet"),
    isUseMealResultSheet: "식단 결과지",
    isUseWaterResultSheet: t("body-water-result-sheet"),
    isUseThermalResultSheet: t("thermal-result-sheet"),
    isUseOptionResultSheet: "옵션 결과지",
    isUseResearchResultSheet: t("research-result -sheet"),
    isUseCompareResultSheet: t("comparison-result-sheet"),
    isUseVisceralFatResultSheet: t("visceral-fat-result-sheet"),
  };
  const EquipMeasureModeType = [
    { key: "ExpertMode", label: t("self-modeOFF") },
    { key: "SelfMode", label: t("self-modeON") },
  ];
  const EquipUserManageType = [
    { key: "UserId", label: t("id") },
    { key: "PhoneNumber", label: t("mobile-number") },
  ];
  const EquipWeightInputType380580 = [
    { key: "ManualInput", label: t("manual-input-mode") },
    { key: "MeasureMode", label: t("weight-measurement-method") },
    { key: "ParallelMeasure", label: t("automatic-measurement-mode") },
  ];
  const EquipAutoPrintType = [
    { key: "None", label: "None" },
    { key: "NotUse", label: "Not Use" },
    { key: "Page1AutoPrint", label: t("on-1Copy") },
    { key: "Page2AutoPrint", label: t("on-2Copies") },
  ];

  const onLoad = useCallback(() => {
    Loading({ type: "LOADING" });
    ProductSearchApi.GetEquipSetting(serial, nation)
      .then((res) => {
        SetData({ ...res.data, equipSerial: serial });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading, serial, nation]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      {/* 장비설정 */}
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <SubTitleDiv title={t("general-setting")}></SubTitleDiv>
        {["Admin", "HeadA", "Engineer"].includes(user.authority) && (
          <ChangeModal data={data} nation={nation} onLoad={onLoad}></ChangeModal>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                <TitleCell>{t("country")}</TitleCell>
                <ContentCell>{data.countryCodeISO === "None" ? "-" : data.countryCodeISO}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("language")}</TitleCell>
                <ContentCell>{data.language === "None" ? "-" : data.language}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("units")}</TitleCell>
                <ContentCell>
                  {data.unit === "None" ? "-" : EquipUnitTypestring.find((x) => x.key === data.unit)?.label}
                </ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("test-mode")}</TitleCell>
                <ContentCell>
                  {data.useMode === "None"
                    ? "-"
                    : EquipMeasureModeType.find((mode) => mode.key === data.useMode)?.label}
                </ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("member-management-method")}</TitleCell>
                <ContentCell>
                  {data.userManageMode === "None"
                    ? "-"
                    : EquipUserManageType.find((mode) => mode.key === data.userManageMode)?.label}
                </ContentCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                <TitleCell>{t("weight-input-method")}</TitleCell>
                <ContentCell>
                  {data.weightInputMode === "None"
                    ? "-"
                    : EquipWeightInputType380580.find((mode) => mode.key === data.weightInputMode)?.label}
                </ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 결과지 설정 */}
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <SubTitleDiv title={t("results-sheet-settings")}></SubTitleDiv>
        {["Admin", "HeadA", "Engineer"].includes(user.authority) && (
          <ChangeSheetModal data={data} nation={nation} onLoad={onLoad}></ChangeSheetModal>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                <TitleCell>{t("results-sheet-type")}</TitleCell>
                <ContentCell colSpan={3}>
                  {Object.keys(DisplayResultSheet).map((property, index, array) => {
                    const key = property as keyof ResultSheet;
                    const value = DisplayResultSheet[key];
                    const isFirst = index === 0;
                    const comma = isFirst ? "" : ", ";
                    return (
                      data.resultSheetDetail[key] && (
                        <span style={{ fontSize: "14px" }} key={property}>{`${comma}${value}`}</span>
                      )
                    );
                  })}
                </ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("print-results-A4")}</TitleCell>
                <ContentCell>{data.isResultSheetPaperA4 ? t("use-on") : t("use-off")}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("automatic-printing")}</TitleCell>
                <ContentCell>{EquipAutoPrintType.find((mode) => mode.key === data.autoPrint)?.label}</ContentCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                <TitleCell>{t("logo")}</TitleCell>
                <ContentCell>{data.isUseLogo ? t("registered") : t("not-registered")}</ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 기타 설정 */}
      <Grid item xs={12}>
        <SubTitleDiv title={t("miscellaneous-settings")}></SubTitleDiv>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                <TitleCell>{t("k-switch")}</TitleCell>
                <ContentCell>{data.isOnKillSwitch ? t("use-on") : t("use-off")}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("k-switchmode")}</TitleCell>
                <ContentCell>{data.isUseKillSwitchMode ? "On" : "Off"}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>URL</TitleCell>
                <ContentCell
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                >
                  {data.apiUrl === "" ? "-" : data.apiUrl}
                  {/* {data.apiUrl && ["Admin", "HeadA", "Engineer"].includes(user.authority) && (
                    <ChangeURL serial={serial} nation={nation} originUrl={data.apiUrl}></ChangeURL>
                  )} */}
                </ContentCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                <TitleCell>{t("lookinBody0-account-login")}</TitleCell>
                <ContentCell>{data.isLoginLB ? t("logged-in") : t("logged-out")}</ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 연결 상태 */}
      <Grid item xs={12}>
        <SubTitleDiv title={t("connection-status")}></SubTitleDiv>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                <TitleCell>{t("internet")}</TitleCell>
                <ContentCell>{data.isNetworkConnection ? t("connect-on") : t("connect-off")}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("bluetooth")}</TitleCell>
                <ContentCell>{data.isBluetoothConnection ? t("connect-on") : t("connect-off")}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("printer")}</TitleCell>
                <ContentCell>{data.isConnectPrint ? t("connect-on") : t("connect-off")}</ContentCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #d9d9d9" }}>
                <TitleCell>Serial</TitleCell>
                <ContentCell>
                  {EquipConnectSerialPortType.find((mode) => mode.key === data.connectSerialPort)?.label}
                </ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default SettingMain;
