import { Grid } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import SubTitleDiv from "components/Common/SubTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton } from "style/theme";
import { EventList } from "system/types";

function EventReportList() {
  const { t } = useTranslation();
  const [data, setData] = useState<EventList[]>([]);
  const [serial, setSerial] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const settings: TextWrapSettingsModel = { wrapMode: "Content" };
  const pageOptions: PageSettingsModel = { pageSize: 20 };
  const storedData = localStorage.getItem("qc-data");

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.event) {
        setData(parsedData.event);
      }
      if (parsedData && parsedData.serial) {
        setSerial(parsedData.serial);
      }
    }
  }, [storedData]);

  const onChange = (args: any) => {
    setSearch(args.target.value);
  };

  const onSearch = () => {
    if (search === "") {
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.event) {
          setData(parsedData.event);
        }
      }
    } else {
      setData(data.filter((x) => x.eventCode === search));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="Event Report"></TitleDiv>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent={"space-between"}>
        <Grid display="flex" alignItems="center">
          <TextBoxComponent
            onChange={onChange}
            value={search}
            cssClass="e-outline"
            placeholder="Search code"
          ></TextBoxComponent>
          <DarkButton onClick={onSearch} style={{ marginLeft: "16px" }}>
            {t("search")}
          </DarkButton>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "5px" }}>{t("serial-number") + ": "}</span>
          <SubTitleDiv title={serial} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "24px", marginBottom: "80px" }}>
        <GridComponent
          className="eventReport"
          dataSource={data}
          allowTextWrap={true}
          textWrapSettings={settings}
          allowPaging={true}
          pageSettings={pageOptions}
          gridLines="Both"
        >
          <ColumnsDirective>
            <ColumnDirective field="datetime" headerText={t("dateandtime")} width="100" />
            <ColumnDirective field="eventCode" headerText={t("event-code")} width="120" />
            <ColumnDirective
              field="part"
              headerText={t("part")}
              width="120"
              template={(rowData: any) => t(rowData.part)}
            />
            <ColumnDirective
              field="situation"
              headerText={t("problem-description")}
              width="200"
              template={(rowData: any) => t(rowData.situation)}
            />
            <ColumnDirective
              field="possibility"
              headerText={t("possible-cause")}
              width="200"
              template={(rowData: any) => t(rowData.possibility)}
            />
          </ColumnsDirective>
          <Inject services={[Page]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default EventReportList;
