import { Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import SubTitleDiv from "components/Common/SubTitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ContentCell,
  LightButton,
  PRIMARY,
  RedTextButton,
  TableContentCell,
  TableTitleCell,
  TitleCell,
} from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { isSplitInt } from "system/Helper";
import { EventList, QCReport } from "system/types";
import { ProductSearchRoutes } from "system/types/routeList";
import NoiseGraph from "./NoiseGraph";
import SideNoiseGraph from "./SideNoiseGraph";

interface QCDataMainProps {
  serial: string;
  nation: string;
}

function QCDataMain({ serial, nation }: QCDataMainProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const history = useHistory();
  const loading = useLoadingDispatch();
  const [data, setData] = useState<QCReport>();

  useEffect(() => {
    if (serial) {
      localStorage.removeItem("qc-data");
      loading({ type: "LOADING_MESSAGE", message: "Analyzing..." });
      ProductSearchApi.GetQCReport(serial, nation)
        .then((res) => {
          setData(res);
          localStorage.setItem("qc-data", JSON.stringify(res));
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    }
  }, [loading, nation, serial]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onChangeEventReport = () => {
    history.push({ pathname: `${ProductSearchRoutes.root}/detail/event-report/${nation}/${serial}` });
  };

  const printQCData = () => {
    if (data) {
      fetch(data.url)
        .then((response) => response.text())
        .then((text) => {
          const printWindow = window.open("", "_blank");
          if (printWindow) {
            printWindow.document.write(`<pre>${text}</pre>`);
            printWindow.document.close();
            printWindow.print();
          }
        })
        .catch(() => {
          alert("Could not start pring job");
        });
    } else {
      alert("No data available for printing");
    }
  };

  // const openQCData = () => {
  //   if (data) {
  //     window.open(data.url, "QC_Report", "status=no,scrollbars=yes,width=1100px,height=1050px,noopener");
  //   } else {
  //     alert("No data available to view");
  //   }
  // };

  const openQCData = async () => {
    if (data) {
      try {
        const response = await fetch(data.url);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl, "QC_Report", "status=no,scrollbars=yes,width=1100px,height=1050px,noopener");
      } catch (error) {
        console.error("Error fetching QC data:", error);
        alert("Failed to open QC data");
      }
    } else {
      alert("No data available to view");
    }
  };

  if (!data) {
    return (
      <div>
        <Typography variant="body1">{t("no-data-found")}</Typography>
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      {/* 프로그램 버전 */}
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Grid>
          <SubTitleDiv title="Program Version"></SubTitleDiv>
        </Grid>
        <Grid>
          <LightButton style={{ minWidth: "120px", width: "auto", marginRight: "16px" }} onClick={printQCData}>
            {t("qc-print")}
          </LightButton>
          <LightButton style={{ minWidth: "120px", width: "auto" }} onClick={openQCData}>
            {t("qc-view")}
          </LightButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d3d3d3" }}>
                <TitleCell>Program Version</TitleCell>
                <ContentCell>
                  <pre>{data?.program.programVersion}</pre>
                </ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>First Entered Noise</TitleCell>
                <ContentCell>{data?.program.firstEnteredNoise}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>First Entered Noise Difference</TitleCell>
                <ContentCell>{data?.program.firstEnteredNoiseDifference}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>Noise should be under</TitleCell>
                <ContentCell>{data?.program.noiseshouldbeunder}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>First Measurement Time</TitleCell>
                <ContentCell>{data?.program.firstMeasurementTime}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>Total Measurement Count</TitleCell>
                <ContentCell>{data?.program?.totalMeasurementCount}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>Personal Info Version</TitleCell>
                <ContentCell>{data?.program.personalInfoVersion}</ContentCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #d3d3d3" }}>
                <TitleCell>Country / Language</TitleCell>
                <ContentCell>{data?.program.selectCountryLanguage}</ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 이벤트 리포트 */}
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Grid>
          <SubTitleDiv title="Event Report"></SubTitleDiv>
        </Grid>
        <Grid>
          <RedTextButton onClick={onChangeEventReport}>{t("view-all")}</RedTextButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "80px", height: "100%" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableTitleCell>{t("dateandtime")}</TableTitleCell>
                <TableTitleCell>{t("event-code")}</TableTitleCell>
                <TableTitleCell>{t("part")}</TableTitleCell>
                <TableTitleCell>{t("problem-description")}</TableTitleCell>
                <TableTitleCell>{t("possible-cause")}</TableTitleCell>
              </TableRow>
              {data?.event.slice(undefined, 5).map((d: EventList) => {
                return (
                  <TableRow key={d.datetime + d.eventCode}>
                    <TableContentCell>{d.datetime}</TableContentCell>
                    <TableContentCell>{d.eventCode}</TableContentCell>
                    <TableContentCell>{t(d.part)}</TableContentCell>
                    <TableContentCell>{t(d.situation)}</TableContentCell>
                    <TableContentCell>{t(d.possibility)}</TableContentCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 노이즈 그래프 */}
      <Grid item xs={12}>
        <SubTitleDiv title="Noise Report"></SubTitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: PRIMARY },
          }}
        >
          <Tab value={1} label={t("noise")} sx={{ fontSize: "18px", fontWeight: value === 1 ? "bold" : "normal" }} />
          <Tab
            value={2}
            label={t("side-noise")}
            sx={{ fontSize: "18px", fontWeight: value === 2 ? "bold" : "normal" }}
          />
        </Tabs>
        <br />
        <br />
        {value === 1 && (
          <NoiseGraph
            data={data.noise !== undefined ? data.noise : []}
            underLine={isSplitInt(
              data?.program.noiseshouldbeunder !== undefined ? data.program.noiseshouldbeunder : ""
            )}
          ></NoiseGraph>
        )}
        {value === 2 && (
          <SideNoiseGraph
            data={data?.noise !== undefined ? data.noise : []}
            underLine={isSplitInt(
              data?.program.noiseshouldbeunder !== undefined ? data.program.noiseshouldbeunder : ""
            )}
          ></SideNoiseGraph>
        )}
      </Grid>
    </Grid>
  );
}

export default QCDataMain;
