import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton, LightButton, RadioCustom, RedTextButton, SearchDropDown } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { EquipUnitTypestring } from "system/Constants";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { isChangeDateFormat } from "system/Helper";
import { EquipSetting } from "system/types";
import ChangePopUp from "./ChangePopUp";

interface ChangeModalProps {
  data: EquipSetting;
  nation: string;
  onLoad: () => void;
}

function ChangeModal({ data, nation, onLoad }: ChangeModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<EquipSetting>(data);
  const Loading = useLoadingDispatch();
  const EquipMeasureModeType = [
    { key: "ExpertMode", label: t("self-modeOFF") },
    { key: "SelfMode", label: t("self-modeON") },
  ];
  const EquipWeightInputType380580 = [
    { key: "ManualInput", label: t("manual-input-mode") },
    { key: "MeasureMode", label: t("weight-measurement-method") },
    { key: "ParallelMeasure", label: t("automatic-measurement-mode") },
  ];
  const EquipWeightInputType = [
    { key: "ManualInput", label: t("manual-input-mode") },
    { key: "MeasureMode", label: t("weight-measurement-method") },
  ];

  const Validation = (data: EquipSetting) => {
    if (data.useMode === "None" || data.unit === "None" || data.weightInputMode === "None") {
      alert(t("fill-blank-fields"));
      return false;
    } else if (
      (data.equip.startsWith("INBODY380") || data.equip.startsWith("INBODY580")) &&
      data.userManageMode === "None"
    ) {
      alert(t("fill-blank-fields"));
      return false;
    }
    return true;
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (Validation(updatedData)) {
      Loading({ type: "LOADING" });
      ProductSearchApi.PutEquipSetting(updatedData.equipSerial, nation, updatedData)
        .then(() => {
          onLoad();
          setOpen(false);
          setPopup(true);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const onChangeData = (e: any) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const onDateChange = (args: any) => {
    const selectedDate = args.value;
    const formattedDateString = isChangeDateFormat(selectedDate);
    setUpdatedData({
      ...updatedData,
      datetime: formattedDateString,
    });
  };

  return (
    <div>
      <RedTextButton onClick={handleClickOpen}>{t("edit")}</RedTextButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" style={{ marginTop: "65px" }}>
        <DialogContent style={{ padding: "56px 65px" }}>
          <Grid container spacing={2} display="flex" alignItems="center">
            {/* 일반 설정 */}
            <Grid item xs={12}>
              <TitleDiv title={t("general-setting")}></TitleDiv>
              <Divider></Divider>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("country")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextBoxComponent
                style={{ width: "344px", height: "40px", borderColor: "#d9d9d9" }}
                value={updatedData.countryCodeISO}
                disabled
                cssClass="e-outline"
              ></TextBoxComponent>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("language")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextBoxComponent
                style={{ width: "344px", height: "40px", borderColor: "#d9d9d9" }}
                value={updatedData.language}
                disabled
                cssClass="e-outline"
              ></TextBoxComponent>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("units")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.unit}
                onChange={onChangeData}
                name="unit"
                fields={{ text: "label", value: "key" }}
                dataSource={EquipUnitTypestring}
              ></SearchDropDown>
            </Grid>
            {/* 날짜 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("dateandtime")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <DatePickerComponent
                width="100%"
                cssClass="e-outline"
                value={new Date(updatedData.datetime)}
                format="yyyy-MM-dd"
                onChange={onDateChange}
                showClearButton={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("test-mode")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.useMode}
                onChange={onChangeData}
                name="useMode"
                fields={{ text: "label", value: "key" }}
                dataSource={EquipMeasureModeType}
              ></SearchDropDown>
            </Grid>
            {["INBODY380", "INBODY580", "INBODY380H", "INBODY580H"].includes(updatedData.equip) && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" fontWeight="bold" width="150px">
                    {t("member-management-method")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{ display: "flex" }}>
                    <RadioGroup
                      row
                      style={{ justifyContent: "space-between" }}
                      name="userManageMode"
                      value={updatedData.userManageMode}
                      onChange={onChangeData}
                    >
                      <FormControlLabel
                        style={{ marginRight: "105px" }}
                        value="UserId"
                        control={<RadioCustom />}
                        label={<span style={{ fontSize: "14px" }}>{t("id")}</span>}
                      />
                      <FormControlLabel
                        value="PhoneNumber"
                        control={<RadioCustom />}
                        label={<span style={{ fontSize: "14px" }}>{t("mobile-number")}</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("weight-input-method")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.weightInputMode}
                onChange={onChangeData}
                name="weightInputMode"
                fields={{ text: "label", value: "key" }}
                dataSource={
                  ["INBODY380", "INBODY580", "INBODY380H", "INBODY580H"].includes(updatedData.equip)
                    ? EquipWeightInputType380580
                    : EquipWeightInputType
                }
              ></SearchDropDown>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" style={{ marginTop: "70px" }}>
            <LightButton onClick={handleClose}>{t("cancel")}</LightButton>
            <DarkButton onClick={handleSave} style={{ marginBottom: "0px", marginLeft: "8px" }}>
              {t("save")}
            </DarkButton>
          </Grid>
        </DialogContent>
      </Dialog>
      {popup && <ChangePopUp popup={popup}></ChangePopUp>}
    </div>
  );
}

export default ChangeModal;
