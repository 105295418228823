import { styled, Typography } from "@mui/material";

const HeaderTitleBar = styled("div")(({ theme }) => ({
  marginBottom: "4px",
}));

interface SubTitleDivProps {
  title: string;
}

function SubTitleDiv({ title }: SubTitleDivProps) {
  return (
    <HeaderTitleBar>
      <Typography variant="h6" fontWeight="bold" style={{ fontSize: "18px" }}>
        {title}
      </Typography>
    </HeaderTitleBar>
  );
}

export default SubTitleDiv;
