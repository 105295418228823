import { Grid, Typography } from "@mui/material";
import MainTitleDiv from "components/Common/MainTitleDiv";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton, LightButton } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { FirmwareLang } from "system/Constants";
import {
  FirmwareModal,
  FirmwareReq,
  FirmwareRes,
  FirmwareResources,
  FirmwareType,
  INIT_FIRMWAREREQ,
} from "system/types";
import CurrentVersion from "./CurrentVersion";
import CustomModal from "./CustomModal";
import LatestVersion from "./LatestVersion";

interface FirmwareMainProps {
  serial: string;
  nation: string;
}

function FirmwareMain({ serial, nation }: FirmwareMainProps) {
  const { t } = useTranslation();
  const eventSourceRef = useRef<EventSource | null>(null); // EventSource 참조
  const storedData = localStorage.getItem("qc-data");
  const [isConnected, setIsConnected] = useState(false); // 연결 상태 관리
  const [isInitDataReady, setIsInitDataReady] = useState(false);
  const [initData, setInitData] = useState<FirmwareReq>({ ...INIT_FIRMWAREREQ, equipSerial: serial });
  const [status, setStatus] = useState<FirmwareType>("up-to-date");
  const [modalName, setModalName] = useState<FirmwareModal>("connect");
  const [modalOpen, setModalOpen] = useState(false);
  const [latestData, setLatestData] = useState<FirmwareReq>(INIT_FIRMWAREREQ);
  const [resources, setResources] = useState<FirmwareResources[]>(
    FirmwareLang.map(
      (lang) =>
        ({
          id: 0,
          key: lang.key,
          label: lang.label,
          checked: false,
          disabled: false, // 필요 시 특정 언어를 비활성화할 수 있습니다.
        } as FirmwareResources)
    )
  );

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.program.programVersion) {
        // 정규식 사용해 펌웨어 언어 목록 추출
        const keys: string[] = [...parsedData.program.programVersion.matchAll(/\b([A-Z_]{2,})_\d{4}\b/g)].map(
          (match) => match[1]
        );
        // Digital, Analog, Loadcell 추출
        const firstLineMatch = parsedData.program.programVersion.match(/^:\s*([^\n]+)/);
        if (firstLineMatch) {
          const items = firstLineMatch[1].split("/");

          let digitalItem = "";
          let loadcellItem = "";
          let analogItem = "";

          // 조건에 따라 항목을 분류하고 저장
          items.forEach((item: string) => {
            if (item.toLowerCase().includes("dm")) {
              digitalItem = item || "-";
            }
            if (item.toLowerCase().includes("lcb")) {
              loadcellItem = item || "-";
            }
            if (item.toLowerCase().includes("bwa") || item.toLowerCase().includes("ano")) {
              analogItem = item || "-";
            }
          });

          // FirmwareReq에 맞춰 initData 업데이트
          setInitData({
            ...initData,
            equipSerial: serial,
            digital: digitalItem || "-",
            loadcell: loadcellItem || "-",
            analog: analogItem || "-",
            resources: keys,
          });
          setIsInitDataReady(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedData]);

  useEffect(() => {
    if (!isInitDataReady) return;

    // const data = {
    //   equipSerial: "EquipSerial",
    //   digital: "DNO3-380DM-0330",
    //   analog: "ANO6-A010",
    //   loadcell: "970LCB-C000",
    //   resources: [],
    // };
    ProductSearchApi.GetLatestFirmware(initData)
      .then((res) => {
        if (res.isSuccess) {
          console.log(res);
          // digital, analog, loadcell의 id 0일 경우 최신 버전
          const newLatestData: FirmwareReq = {
            equipSerial: res.data.equipSerial,
            digital: res.data.digital.id !== 0 ? res.data.digital.version : initData.digital,
            analog: res.data.analog.id !== 0 ? res.data.analog.version : initData.analog,
            loadcell: res.data.loadcell.id !== 0 ? res.data.loadcell.version : initData.loadcell,
            resources: [], // 로직에 따라 resource를 추가로 처리
          };

          const isLatestVersion =
            res.data.digital.id !== 0 &&
            res.data.analog.id !== 0 &&
            res.data.loadcell.id !== 0 &&
            res.data.resources.every((resource) => initData.resources.includes(resource.version));

          if (isLatestVersion) {
            setStatus("up-to-date");
          } else {
            // 최신 버전 존재한다면 connect 가능
            setLatestData(newLatestData);
            handleApiResponse(res);
            setStatus("update-available");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitDataReady]);

  const handleApiResponse = (res: FirmwareRes) => {
    const enabledResources = res.data.resources.map((resource) => resource.version);

    // initData에 있는 리소스 체크 여부 확인
    const initCheckedResources = initData.resources;

    // initdata에 있으면 체크 true, latestdata에 있으면 다운가능한 상태(체크 활성화)
    const updatedCheckboxState = FirmwareLang.map((lang) => {
      const matchingResource = res.data.resources.find((resource) => resource.version === lang.key);

      return {
        id: matchingResource ? matchingResource.id : 0,
        key: lang.key,
        label: lang.label,
        checked: initCheckedResources.includes(lang.key),
        disabled: !enabledResources.includes(lang.key),
      };
    });
    setResources(updatedCheckboxState);
  };

  const onClickConnect = () => {
    const eventSource = new EventSource(
      `https://backofficeapitest.azurewebsites.net/Cs/ConnectingInBodyFirmwareUpdate?equipSerial=${serial}`
    );
    eventSourceRef.current = eventSource; // EventSource 저장
    const timeoutId = setTimeout(() => {
      console.log("Connection attempt timed out after 5 minutes");
      setModalName("connect-error");
      eventSource.close();
    }, 5 * 60 * 1000); // 5분 후 연결 종료

    eventSource.onmessage = (event) => {
      console.log(event.data);
      if (event.data === "ServerClose") {
        eventSource.close();
        clearTimeout(timeoutId);
      }
      if (event.data === "Error") {
        eventSource.close();
        setModalName("connect-error");
        clearTimeout(timeoutId);
      }
      if (event.data === "WatingInBodyConnection") {
        clearTimeout(timeoutId);
      }
      if (event.data === "ResponseInBody") {
        if (!isConnected) {
          // 연결이 아직 되지 않은 경우에만 모달 표시
          setModalName("connect-success");
          setStatus("down-available");
          setIsConnected(true);
        }
      }
    };

    eventSource.onerror = function () {
      console.log("Error occurred in SSE connection");
      setModalName("connect-error");
      eventSource.close();
      clearTimeout(timeoutId);
    };
  };

  // Connect 버튼 클릭 시 호출
  const handleConnect = async () => {
    setModalOpen(true);
    try {
      onClickConnect(); // SSE 연결 시작
    } catch (error) {
      setModalName("connect-error");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    // 모달 닫기 시 EventSource 닫기
    if (status === "update-available") {
      if (eventSourceRef.current) {
        eventSourceRef.current.close(); // EventSource 연결 종료
        console.log("EventSource closed");
      }
    }
  };

  const handleDownload = () => {
    setModalOpen(true);
    setModalName("down-confirm");
    setStatus("down-available");
  };

  const renderTitle = () => {
    switch (status) {
      case "up-to-date":
        return <MainTitleDiv title={t("version-up-to-date")}></MainTitleDiv>;
      case "update-available":
        return <MainTitleDiv title={t("update-available")}></MainTitleDiv>;
      case "down-available":
        return <MainTitleDiv title={t("update-available")}></MainTitleDiv>;
      case "down-success":
        return (
          <>
            <MainTitleDiv title={t("update-in-progress")}></MainTitleDiv>
            <Typography variant="body1" style={{ marginTop: "12px" }}>
              {t("installation-successful")}{" "}
            </Typography>
          </>
        );
      case "down-error":
        return (
          <>
            <MainTitleDiv title={t("unable-update")}></MainTitleDiv>
            <Typography variant="body1" color="#971b2f" style={{ marginTop: "12px" }}>
              {t("installation-unsccessful")}{" "}
            </Typography>
          </>
        );
    }
  };

  return (
    <Grid container spacing={2} style={{ width: "1200px" }}>
      <Grid item xs={12}>
        {renderTitle()}
      </Grid>
      <Grid item xs={12}>
        <CurrentVersion initData={initData}></CurrentVersion>
      </Grid>
      {status !== "up-to-date" && (
        <Grid item xs={12}>
          <LatestVersion
            latestData={latestData}
            status={status}
            resources={resources}
            setResources={setResources}
          ></LatestVersion>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: "80px" }}>
        {status === "update-available" && (
          <LightButton onClick={handleConnect} style={{ width: "260px" }}>
            {t("connect-to-inBody")}
          </LightButton>
        )}
        {status === "down-available" && (
          <DarkButton onClick={handleDownload} style={{ width: "260px" }}>
            {t("download")}
          </DarkButton>
        )}
      </Grid>
      {modalOpen && (
        <CustomModal
          open={modalOpen}
          onClose={closeModal}
          modalName={modalName}
          setModalName={setModalName}
          status={status}
          setStatus={setStatus}
          serial={serial}
          resources={resources}
        ></CustomModal>
      )}
    </Grid>
  );
}

export default FirmwareMain;
