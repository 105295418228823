import { Checkbox, createTheme, MenuItem, Pagination, TableCell, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { AutoCompleteComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3.8rem",
    },
    h2: {
      fontSize: "3.3rem",
    },
    h3: {
      fontSize: "2.5rem",
    },
    h4: {
      fontSize: "2.0rem",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      light: "#666f73",
      main: "#971B2F",
      dark: "#2c3438",
      contrastText: "#fff",
    },
    secondary: {
      light: "#A2AAAD",
      main: "#5B6770",
      dark: "#601317",
      contrastText: "#fff",
    },
  },
});

export const ContentWrapper = styled("div")(({ theme }) => ({
  minWidth: "1200px",
  marginTop: "20px",
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: "200ms",
  }),
  marginLeft: 280,
}));

export const ContentShift = styled("div")(({ theme }) => ({
  minWidth: "1200px",
  marginTop: "20px",
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: "200ms",
  }),
  marginLeft: 50,
}));

export const ContentBody = styled("div")(({ theme }) => ({
  maxWidth: "2200px",
  minWidth: "1500px",
  "@media (max-width: 1680px)": {
    width: "1440px",
  },
  "@media (max-width: 1440px)": {
    width: "1380px",
  },
  "@media (max-width: 1380px)": {
    width: "1280px",
  },
}));

// header
export const MenuButton = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
export const Title = styled("div")(() => ({
  flexGrow: 1,
  marginLeft: "10px",
}));
export const NavProfile = styled("div")(() => ({
  marginRight: "10px",
}));

export const FooterStyle = styled("div")(({ theme }) => ({
  height: "80px",
  width: "100%",
  padding: "10px",
  position: "absolute",
  marginTop: theme.spacing(3),
  textAlign: "center",
  zIndex: -1,
}));

export const BodyWrap = styled("div")(({ theme }) => ({
  minHeight: "100%",
  position: "relative",
  marginTop: "60px",
  padding: "2px",
  marginLeft: "20px",
}));
// login
export const WrapperLogin = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
}));

export const LoginDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  border: "1px solid #9e9e9e",
  borderRadius: "15px",
  width: "300px",
  padding: "80px 50px 50px 50px",
}));

export const HelpLink = styled("p")(({ theme }) => ({
  textDecoration: "underline",
  color: "blue",
  fontSize: "14px",
}));

// button
export const RedButton = styled(ButtonComponent)({
  backgroundColor: "#91001e",
  border: "1px solid #91001e",
  color: "white",
  boxShadow: "none",
  padding: "10px",
  height: "40px",
  borderRadius: "6px",
  fontSize: "14px",
  width: "80px",
  marginBottom: "4px",
});

export const RedTextButton = styled(ButtonComponent)({
  background: "transparent",
  border: "none",
  color: "#91001e",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "6px",
  fontSize: "20px",
  fontWeight: "bold",
  marginBottom: "4px",
  textTransform: "none",
});

export const DarkButton = styled(ButtonComponent)({
  backgroundColor: "#3c3c3b",
  border: "1px solid #3c3c3b",
  color: "white",
  boxShadow: "none",
  padding: "10px",
  height: "40px",
  borderRadius: "6px",
  fontSize: "14px",
  width: "80px",
  marginBottom: "4px",
});

export const LightButton = styled(ButtonComponent)({
  backgroundColor: "#fff",
  border: "1px solid #9b9b9b",
  boxShadow: "none",
  padding: "10px",
  height: "40px",
  borderRadius: "6px",
  fontSize: "14px",
  width: "80px",
});

export const LightRedButton = styled(ButtonComponent)({
  backgroundColor: "#fff",
  border: "1px solid #CC1F3B",
  color: "#CC1F3B",
  boxShadow: "none",
  padding: "10px",
  height: "40px",
  borderRadius: "6px",
  fontSize: "14px",
  width: "80px",
});

export const TextButton = styled(ButtonComponent)({
  backgroundColor: "#fff",
  border: "none",
  boxShadow: "none",
  height: "40px",
  fontSize: "14px",
});

export const LinkButton = styled(ButtonComponent)({
  backgroundColor: "inherit",
  color: "blue",
  border: "none",
  boxShadow: "none",
  fontSize: "12px",
  margin: "2px",
  padding: "4px 2px",
});

// 검색
export const SearchBox = styled(TextBoxComponent)({
  width: "344px",
  height: "40px",
  padding: "10px 24px 10px 16px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});

export const MiniTextBox = styled(TextBoxComponent)({
  width: "240px !important",
  height: "40px",
  padding: "10px 24px 10px 16px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});

export const SearchDropDown = styled(DropDownListComponent)(() => ({
  width: "344px",
  height: "40px",
  borderColor: "#d9d9d9",
}));
export const TitleCell = styled(TableCell)({
  color: "#9b9b9b",
  fontSize: "16px",
  width: "200px",
  paddingLeft: "40px",
  border: "0px",
  fontWeight: "bold",
});
export const TableTitleCell = styled(TableCell)({
  fontSize: "16px",
  fontWeight: "bold",
  backgroundColor: "#f8f8f8",
  width: "317px",
  paddingLeft: "40px",
});
export const ContentCell = styled(TableCell)({
  width: "512px",
  fontSize: "16px",
  fontWeight: "bold",
  paddingLeft: "40px",
  border: "0px",
  borderCollapse: "collapse",
});
export const TableContentCell = styled(TableCell)({
  width: "512px",
  fontSize: "16px",
  paddingLeft: "40px",
  border: "0px",
  borderCollapse: "collapse",
});
export const SmallTitleCell = styled(TableCell)({
  color: "#9b9b9b",
  fontSize: "16px",
  width: "245px",
  borderRight: "1px solid #d9d9d9",
  borderBottom: "1px solid #d9d9d9",
});
export const NonborderSmallTitleCell = styled(TableCell)({
  color: "#9b9b9b",
  fontSize: "16px",
  width: "245px",
  borderBottom: "1px solid #d9d9d9",
});
export const RadioCustom = styled(Radio)({
  fontSize: "14px",
  "&.Mui-checked": {
    color: "black",
  },
});
export const CheckboxCustom = styled(Checkbox)({
  fontSize: "14px",
  "&.Mui-checked": {
    color: "black",
  },
});
export const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    backgroundColor: "#f0f2f5",
  },
  "& .MuiPaginationItem-root": {
    color: "black",
  },
});

// crm
export const TitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
});
export const RedTitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#FFF0F3",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
});
export const GreenTitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#F0FAF7",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
});
export const YellowTitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#FCF1E3",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
});

export const BodyTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
}));

export const PrintTitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  padding: "4px",
  fontSize: "12px",
});

export const PrintBodyTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  whiteSpace: "pre-wrap",
  padding: "4px",
  fontSize: "11px",
}));

export const PrintLinkButton = styled(ButtonComponent)({
  backgroundColor: "#fff",
  color: "blue",
  border: "none",
  boxShadow: "none",
  fontSize: "11px",
  margin: "2px",
  padding: "4px 2px",
});

export const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  whiteSpace: "nowrap",
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  padding: "10px",
  textAlign: "center",
}));
export const FormButton = styled(ButtonComponent)(({ theme }) => ({
  height: "30px",
  fontSize: "12px",
  whiteSpace: "nowrap",
  backgroundColor: "#fff",
  border: "1px solid #9b9b9b",
  padding: "4px 10px",
  margin: "3px",
  boxShadow: "none",
  borderRadius: "6px",
}));
export const FormTextBox = styled(TextBoxComponent)({
  height: "25px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});
export const FormInput = styled(TextField)({
  borderRadius: "6px",
  borderColor: "#d9d9d9",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    padding: "7px 10px",
    height: "22px",
  },
});
export const FormNumericBox = styled(NumericTextBoxComponent)({
  height: "25px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});
export const FormDropDown = styled(DropDownListComponent)({
  height: "25px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});
export const FormSelectMenuItem = styled(MenuItem)({
  height: "20px",
  "& .MuiTypography-root": {
    fontSize: "12px",
  },
});
export const FormSelectCheckBox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#e3165b",
  },
});
export const BlackFormSelectCheckBox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#000000",
  },
});
export const FormAutoComplete = styled(AutoCompleteComponent)({
  height: "25px",
  borderRadius: "6px",
  border: "solid 1px #d9d9d9",
  backgroundColor: "#fff",
});
