import client from "system/ApiService";
import HttpClient from "system/http-client";
import {
  EquipSetting,
  EquipSettingResponse,
  EquipURL,
  FirmwareDownload,
  FirmwareReq,
  FirmwareRes,
  QCDashboard,
  QCReport,
} from "system/types";

class _ProductSearchApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  // product search
  GetInfomationOfProduct(serial: string) {
    return this.client.get(`product/information/${serial}`);
  }
  GetMaterialHistoryOfProduct(serial: string) {
    return this.client.get(`product/materialhistory/${serial}`);
  }
  GetQCReport(serial: string, nation: string) {
    return this.client.get<QCReport>(`product/qcreport/${serial}/${nation}`);
  }
  GetEquipSetting(serial: string, nation: string) {
    return this.client.get<EquipSettingResponse>(`product/setting/${serial}/${nation}`);
  }
  PutEquipSetting(serial: string, nation: string, equipSetting: EquipSetting) {
    return this.client.put(`product/setting/${serial}/${nation}`, equipSetting);
  }
  PutEquipURL(urlData: EquipURL, nation: string) {
    return this.client.put(`product/setting/url/${nation}`, urlData);
  }
  // firmware
  GetLatestFirmware(data: FirmwareReq) {
    return this.client.post<FirmwareRes>("product/firmware", data);
  }
  DownloadFirmware(data: FirmwareDownload) {
    return this.client.post("product/firmware/download", data);
  }
  // dashboard
  GetDashboardList() {
    return this.client.get(`product/dashboard`);
  }
  PutDashboardStatus(data: QCDashboard) {
    return this.client.put(`product/dashboard/status`, data);
  }
}

const ProductSearchApi = new _ProductSearchApi(client);
export default ProductSearchApi;
